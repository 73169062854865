
import * as ipxRuntime$jup2qx8m8x from '/home/runner/work/cdc-web/cdc-web/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as weserveRuntime$sevE10Ku2O from '/home/runner/work/cdc-web/cdc-web/src/imgProviders/weserv.ts'

export const imageOptions = {
  "screens": {
    "xs": 480,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "2xs": 320
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$jup2qx8m8x, defaults: {"maxAge":2592000} },
  ['weserve']: { provider: weserveRuntime$sevE10Ku2O, defaults: {"baseURL":"https://cina-delicious-cakes.co.uk/","modifiers":{"quality":80,"fit":"cover","format":"webp,jpeg"}} }
}
        